<template>
  <div>
    <mobile v-if="mobile"/>
    <desktop v-else/>
  </div>
</template>
<script>
import Desktop from '@/views/apps/invoice/invoice-preview/Desktop/index'
import Mobile from '@/views/apps/invoice/invoice-preview/Mobile/index'

export default {
  components: {
    Desktop,
    Mobile,
  },
  data() {
    return {
      mobile: false,
    }
  },
  created() {
    if (window.innerWidth < 992) {
      this.mobile = true
    }
  },
}
</script>
